import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import App from "./App";
import { Amplify } from "aws-amplify";
import config from "./config";
import Localize from "./Localize";
import * as serviceWorker from "./serviceWorker";
import { createRoot } from "react-dom/client";
import { fetchAuthSession } from "aws-amplify/auth";
import CatchErrors from "./CatchErrors";

const awsConfig = {
  Auth: {
    Cognito: {
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolClientId: config.cognito.APP_CLIENT_ID,
    },
  },
  Storage: {
    S3: {
      region: config.s3.REGION,
      bucket: config.s3.BUCKET,
    },
  },
  API: {
    REST: {
      "Vili API": {
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    },
  },
};

Amplify.configure(
  { ...awsConfig },
  {
    API: {
      REST: {
        headers: async () => {
          return {
            Authorization: (
              await fetchAuthSession()
            ).tokens?.idToken?.toString(),
          };
        },
      },
    },
  }
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Router>
    <Localize>
      <CatchErrors>
        <App />
      </CatchErrors>
    </Localize>
  </Router>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
