import React from "react";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import "./Buttons.css";

const ShowHideButton = (props) => (
  <span className="showhide-btn" onClick={props.showHide}>
    {props.type === "text" ? <IoIosEyeOff size={25} /> : <IoIosEye size={25} />}
  </span>
);

export default ShowHideButton;
