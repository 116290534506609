import React from "react";
import { useIntl } from "react-intl";
import { FaHome } from "react-icons/fa";
import { Nav, Navbar, NavbarBrand, NavItem, NavLink } from "reactstrap";
import HubIcon from "../img/icons/HubIcon";
import "./MainMenu.css";
import MainMenuButton from "./MainMenuButton";

const UserIcon = ({ className, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 496 512"
  >
    <g id="user-icon" fill={fill} fillRule="nonzero">
      <path d="M248 104c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96zm0 144c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm0-240C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-49.7 0-95.1-18.3-130.1-48.4 14.9-23 40.4-38.6 69.6-39.5 20.8 6.4 40.6 9.6 60.5 9.6s39.7-3.1 60.5-9.6c29.2 1 54.7 16.5 69.6 39.5-35 30.1-80.4 48.4-130.1 48.4zm162.7-84.1c-24.4-31.4-62.1-51.9-105.1-51.9-10.2 0-26 9.6-57.6 9.6-31.5 0-47.4-9.6-57.6-9.6-42.9 0-80.6 20.5-105.1 51.9C61.9 339.2 48 299.2 48 256c0-110.3 89.7-200 200-200s200 89.7 200 200c0 43.2-13.9 83.2-37.3 115.9z"></path>
    </g>
  </svg>
);

const MainMenu = ({ handleLogout, currentUser, location }) => {
  const { formatMessage } = useIntl();
  return (
    <Navbar color="light" light>
      <NavbarBrand tag="a">
        <div className="navbar-logo" />
        <small>{currentUser}</small>
      </NavbarBrand>
      <Nav className="main-menu-nav" vertical>
        <MainMenuButton id="/users" active={location.pathname === "/users"}>
          <UserIcon className="nav-item-icon" />
          {formatMessage({ id: "main_nav_users" })}
        </MainMenuButton>
        <MainMenuButton id="/devices" active={location.pathname === "/devices"}>
          <HubIcon className="nav-item-icon" />
          {formatMessage({ id: "main_nav_devices" })}
        </MainMenuButton>
        <MainMenuButton id="/sites" active={location.pathname === "/sites"}>
          <FaHome className="nav-item-icon" />
          {formatMessage({ id: "main_nav_sites" })}
        </MainMenuButton>
        <div className="nav-item-logout">
          <NavItem>
            <NavLink onClick={() => handleLogout()}>
              {formatMessage({ id: "logout" })}
            </NavLink>
          </NavItem>
        </div>
      </Nav>
    </Navbar>
  );
};

export default MainMenu;
