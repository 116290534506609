import React, { Fragment, useState, useEffect } from "react";
import { ListGroup, Fade } from "reactstrap";
import { ErrorAlert, Loader } from "../../Utilities";
import "./details.css";
import { withErrors, usePrevious } from "../../CustomHooks";
import { loadSiteReport } from "../../Frontend-api";
import ListOwnedGateways from "./ListOwnedGateways";
import ListSiteDetails from "./ListSiteDetails";
import DetailsBackLink from "./DetailsBackLink";

const SiteDetails = ({
  siteId,
  resultType,
  resultId,
  selectEntity,
  ...props
}) => {
  const [isLoading, setLoading] = useState(true);
  const [details, setDetails] = useState({});
  const { errorText, handleErrors, toggleError, visible } = props;
  const previousId = usePrevious(siteId);

  useEffect(() => {
    async function getSiteDetails(id) {
      setLoading(true);
      try {
        const details = await loadSiteReport(id);
        setDetails(details);
      } catch (e) {
        handleErrors(e);
      } finally {
        setLoading(false);
      }
    }

    if (siteId && previousId !== siteId) {
      getSiteDetails(siteId);
    }
  }, [siteId, previousId, handleErrors]);

  const handleResultTypeChange = (id, type) => {
    selectEntity(id, type);
  };

  return (
    <Fragment>
      <ErrorAlert
        isOpen={visible}
        toggle={() => toggleError()}
        message={errorText}
      />
      {props.backResultType && (
        <DetailsBackLink
          backResultType={props.backResultType}
          handleResultTypeChange={handleResultTypeChange}
        />
      )}
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        details && (
          <Fade>
            <ListGroup>
              <ListSiteDetails
                {...details}
                resultType={resultType}
                handleResultTypeChange={handleResultTypeChange}
                handleErrors={handleErrors}
              />
              <ListOwnedGateways
                gateways_owned={details.installedGatewaysAndDevices}
                handleResultTypeChange={handleResultTypeChange}
                resultType={resultType}
              />
            </ListGroup>
          </Fade>
        )
      )}
    </Fragment>
  );
};

export default withErrors(SiteDetails);
