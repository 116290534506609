import React from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const CustomCaret = ({ isOpen }) =>
  isOpen ? (
    <FaAngleUp className="custom-caret" />
  ) : (
    <FaAngleDown className="custom-caret" />
  );

  export default CustomCaret;
