import React, { memo, useState, Fragment } from "react";
import {
  Col,
  ListGroupItem,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import { Loader, Detail, DetailHeader } from "../../../Utilities";
import EventNote from "./EventNote";
import "./eventlog.css";

const Eventlog = ({ serialNumber, log, isLoading }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 10;

  const handlePageChange = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  const pageCount = Math.ceil(log.length / pageSize);

  return !isLoading ? (
    <Fragment>
      <ListGroupItem>
        <DetailHeader
          title={<FormattedMessage id="header_log" />}
          info={<FormattedMessage id="header_log" />}
        />
        <Detail
          log={
            <div className="timeline-container">
              <ul className="timeline fa-ul">
                {log
                  .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                  .map(({ ...items }, index) => (
                    <EventNote
                      key={index}
                      serialNumber={serialNumber}
                      {...items}
                    />
                  ))}
              </ul>
            </div>
          }
        />
        {pageCount > 1 && (
          <Detail
            pagination={
              <Row className="pagination-row">
                <Col className="text-right pagination-col">
                  {pageCount > 1 && (
                    <Pagination className="log-pagination">
                      <PaginationItem disabled={currentPage <= 0}>
                        <PaginationLink
                          className="log-pagination-link"
                          onClick={(e) => handlePageChange(e, 0)}
                          first
                          href="#"
                        />
                      </PaginationItem>
                      <PaginationItem disabled={currentPage <= 0}>
                        <PaginationLink
                          className="log-pagination-link"
                          onClick={(e) => handlePageChange(e, currentPage - 1)}
                          previous
                          href="#"
                        />
                      </PaginationItem>

                      {pageCount > 5 ? (
                        <Fragment>
                          {[...Array(pageCount)].map((page, i) => {
                            return (
                              (i === currentPage || i === currentPage + 1) && (
                                <PaginationItem
                                  active={i === currentPage}
                                  key={i}
                                >
                                  <PaginationLink
                                    className="log-pagination-link"
                                    onClick={(e) => handlePageChange(e, i)}
                                    href="#"
                                  >
                                    {i + 1}
                                  </PaginationLink>
                                </PaginationItem>
                              )
                            );
                          })}
                          <PaginationItem disabled>
                            <PaginationLink
                              className="log-pagination-link"
                              href="#"
                            >
                              {"..."}
                            </PaginationLink>
                          </PaginationItem>
                          {
                            <PaginationItem
                              active={currentPage === pageCount - 1}
                            >
                              <PaginationLink
                                className="log-pagination-link"
                                onClick={(e) =>
                                  handlePageChange(e, pageCount - 1)
                                }
                                href="#"
                              >
                                {pageCount}
                              </PaginationLink>
                            </PaginationItem>
                          }
                        </Fragment>
                      ) : (
                        [...Array(pageCount)].map((page, i) => (
                          <PaginationItem active={i === currentPage} key={i}>
                            <PaginationLink
                              className="log-pagination-link"
                              onClick={(e) => handlePageChange(e, i)}
                              href="#"
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        ))
                      )}

                      <PaginationItem disabled={currentPage >= pageCount - 1}>
                        <PaginationLink
                          className="log-pagination-link"
                          onClick={(e) => handlePageChange(e, currentPage + 1)}
                          next
                          href="#"
                        />
                      </PaginationItem>
                    </Pagination>
                  )}
                </Col>
              </Row>
            }
          />
        )}
      </ListGroupItem>
    </Fragment>
  ) : (
    <Loader isLoading={isLoading} />
  );
};

export default memo(Eventlog);
