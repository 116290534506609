import React, { memo, useState, useEffect } from "react";
import { Container } from "reactstrap";
import { withRouter } from "react-router-dom";
import "./App.css";
import Routes from "./Routes";
import { fetchAuthSession, signOut } from "aws-amplify/auth";
import { ErrorAlert, Loader } from "./Utilities";
import { withErrors } from "./CustomHooks";
import { isAdmin } from "./Frontend-api";

const App = (props) => {
  const [isAuthenticated, setAuthenticationStatus] = useState(false);
  const [user, setCurrentUser] = useState("");
  const [isLoading, setLoading] = useState(true);

  const { visible, errorText, toggleError, handleErrors } = props;

  const handleLogin = (username) => {
    setAuthenticationStatus(true);
    setCurrentUser(username);
  };

  useEffect(() => {
    async function checkAuthenticationStatus() {
      try {
        const user = (await fetchAuthSession()).tokens ?? {};
        if (!user.idToken) {
          setLoading(false);
          return;
        }
        const { email } = user.idToken.payload;
        const isUserAdmin = await isAdmin(email);
        if (!isUserAdmin.result) {
          let e = { name: "user_not_admin" };
          handleErrors(e);
          await signOut();
        } else {
          handleLogin(email);
        }
      } catch (e) {
        if (e !== "No current user" || e.message === "Unauthorized") {
          await signOut();
          handleErrors(e);
        }
      } finally {
        setLoading(false);
      }
    }

    checkAuthenticationStatus();
  }, [handleErrors]);

  const handleLogout = async () => {
    await signOut();
    setCurrentUser("");
    setAuthenticationStatus(false);
    props.history.push("/login");
  };

  const childProps = {
    handleLogin,
    handleLogout,
    isAuthenticated,
    user,
  };

  return isLoading ? (
    <Loader isLoading={isLoading} />
  ) : (
    <Container fluid className={isAuthenticated ? "main-app" : "landing"}>
      <ErrorAlert
        isOpen={visible}
        toggle={() => toggleError()}
        message={errorText}
      />
      <Routes childProps={childProps} />
    </Container>
  );
};

export default memo(withErrors(withRouter(App)));
