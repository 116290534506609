import React, { Fragment, useState } from "react";
import { Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useErrorHandling } from "../CustomHooks";
import { ErrorAlert } from "../Utilities";
import { FaFileExcel } from "react-icons/fa";
import { loadReport } from "../Frontend-api";

const ReportButton = ({ title }) => {
  const [isLoading, setLoading] = useState(false);
  const { visible, errorText, toggleError, handleErrors } = useErrorHandling();

  const download = (data) => {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", `${title}list.xlsx`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const createReport = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!title) {
        return;
      }
      const entity = title === "users" ? "users" : "devices";
      const report = await loadReport(entity);

      download(report);
    } catch (e) {
      handleErrors(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <ErrorAlert
        isOpen={visible}
        toggle={() => toggleError()}
        message={errorText}
      />
      <Button className="p-2" color="primary" onClick={(e) => createReport(e)}>
        {isLoading ? (
          <div className="spinning-btn" />
        ) : (
          <FaFileExcel size={25} color="white" />
        )}
        <h6>
          <FormattedMessage id={`report_${title}`} />
        </h6>
      </Button>
    </Fragment>
  );
};

export default ReportButton;
