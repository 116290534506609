import React from "react";
import "./notfound.css";
import { FormattedMessage } from "react-intl";

const NotFound = () =>
  <div className="not-found">
    <h3><FormattedMessage id="page_not_found" /></h3>
  </div>;

export default NotFound;
