import { get, post, put, del } from "aws-amplify/api";

const apiName = "Vili API";

// Is user admin

export const isAdmin = async (email) => {
  const options = { body: { email } };
  return (
    await get({ apiName, path: "/users/is-admin", options }).response
  )?.body.json();
};

// Search
//------------------------------------------------------
export const search = async (entity, searchTerm) => {
  const options = { body: { searchTerm } };
  return (
    await post({
      apiName,
      path: `/${entity}/search`,
      options,
    }).response
  )?.body.json();
};

export const getDetails = async (entities, id) => {
  return (
    entities === "roofs"
      ? await get({ apiName, path: `/${entities}/${id}` }).response
      : await get({ apiName, path: `/${entities}/${id}/details` }).response
  )?.body.json();
};
// MCU data export
//----------------------------------------------------------
export const getMcuExportDownloadUrl = async (deviceId, token) => {
  return (
    token === null
      ? await get({
          apiName,
          path: `/devices/${deviceId}/details/excel`,
        }).response
      : await get({
          apiName,
          path: `/devices/${deviceId}/details/excel/${token}`,
        }).response
  )?.body.json();
};

export const getLog = async (entities, id) => {
  return (
    await get({ apiName, path: `/${entities}/${id}/log` }).response
  )?.body.json();
};
// Site reports
//-----------------------------------------------------------
export const getExportDownloadUrl = async (siteId, token) => {
  return (
    token === null
      ? await get({
          apiName,
          path: `/roofs/${siteId}/reports/measurements-as-excel`,
        }).response
      : await get({
          apiName,
          path: `/roofs/${siteId}/reports/measurements-as-excel/${token}`,
        }).response
  )?.body.json();
};

// This is used to get site details
export const loadSiteReport = async (siteId) => {
  return (
    await get({ apiName, path: `/roofs/${siteId}/reports/device-report` })
      .response
  )?.body.json();
};
//------------------------------------------------------------
// Device detail report
//------------------------------------------------------------
export const loadDeviceDetailReport = (id) => {
  const options = {
    headers: {
      accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "blob",
  };
  return get({ apiName, path: `/devices/${id}/details/excel`, options })
    .response;
};
//------------------------------------------------------------
// Other reports
//------------------------------------------------------------
export const loadReport = async (entity) => {
  let options = {
    body: {},
    headers: {
      accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "blob",
  };
  return (
    await get({ apiName, path: `/${entity}/search/all/excel`, options })
      .response
  )?.body.blob();
};
/**
 * -----------------------------------------------------------
 * Add user
 * -----------------------------------------------------------
 */
export const addUser = async (username) => {
  const options = { body: { email: username } };
  return (
    await post({ apiName, path: "/users", options }).response
  )?.body.json();
};
//-----------------------------------------------------------
// API key
//-----------------------------------------------------------
export const enableKey = async (id) => {
  const options = { body: {} };
  return (
    await put({ apiName, path: `/users/${id}/enable-api`, options }).response
  )?.body.json();
};
export const removeKey = async (id) => {
  const options = { body: {} };
  return (
    await put({ apiName, path: `/users/${id}/disable-api`, options }).response
  )?.body.json();
};
//----------------------------------------------------------
// User management
//----------------------------------------------------------
export const userEnable = async (userId) => {
  const options = { body: {} };
  return (
    await put({ apiName, path: `/users/${userId}/enable`, options }).response
  )?.body.json();
};
export const userDisable = async (userId) => {
  const options = { body: {} };
  return (
    await put({ apiName, path: `/users/${userId}/disable`, options }).response
  )?.body.json();
};
export const userDelete = async (userId) => {
  const options = { body: {} };
  return del({ apiName, path: `/users/${userId}`, options }).response;
};
