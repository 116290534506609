import React, { Fragment, memo, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  Input,
  Form,
  FormGroup,
  Modal,
  Label,
  ModalBody,
} from "reactstrap";
import { CancelButton, LoaderButton } from "../Buttons";
import { useErrorHandling } from "../CustomHooks";
import { ErrorAlert, validateEmail } from "../Utilities";
import { FaUserPlus } from "react-icons/fa";
import { addUser } from "../Frontend-api";

const AddUser = ({ search }) => {
  const [toggle, setToggle] = useState(false);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);

  const { errorText, handleErrors, toggleError, visible } = useErrorHandling();

  useEffect(() => {
    setValid(() => {
      return username.length > 0 && validateEmail(username) !== null;
    });
  }, [username]);

  const resetForm = () => {
    if (visible) {
      toggleError(false);
    }
    setLoading(false);
    setToggle(false);
    setUsername("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const user = await addUser(username);
      if (user) {
        search(user.email);
        resetForm();
      }
    } catch (e) {
      handleErrors(e);
    }
  };

  const { formatMessage } = useIntl();

  const renderForm = () => {
    return (
      <Form className="text-center" onSubmit={(e) => handleSubmit(e)}>
        <h3 className="mb-3">{formatMessage({ id: "invite_user" })}</h3>
        <FormGroup className="text-left">
          <Label tag="h6">
            <strong>{formatMessage({ id: "username" })}</strong>
          </Label>
          <Input
            name="username"
            type="text"
            valid={valid}
            onChange={(e) => setUsername(e.target.value)}
            maxLength={50}
            placeholder={formatMessage({ id: "email_placeholder" })}
          />
        </FormGroup>
        <LoaderButton
          type="submit"
          isLoading={loading}
          disabled={!valid}
          text={"invite"}
          loadingText={"inviting"}
        />
        <CancelButton onClick={() => resetForm()} />
      </Form>
    );
  };

  const renderError = () => {
    return (
      <Fragment>
        <ErrorAlert
          isOpen={visible}
          toggle={() => {
            toggleError();
            resetForm();
          }}
          message={errorText}
        />
        <Button size="sm" color="primary" onClick={() => resetForm()}>
          {formatMessage({ id: "close" })}
        </Button>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Modal
        className="add-user-dialog"
        isOpen={toggle}
        toggle={() => setToggle(!toggle)}
        backdrop={false}
      >
        <ModalBody className="modal-content">
          {errorText.length > 0 ? renderError() : renderForm()}
        </ModalBody>
      </Modal>
      <Button
        className="d-flex flex-row align-items-center p-2 mr-2"
        color="primary"
        onClick={() => setToggle(true)}
      >
        <FaUserPlus className="mx-1" color="white" size={25} />
        <h6>{formatMessage({ id: "invite_user" })}</h6>
      </Button>
    </Fragment>
  );
};

export default memo(AddUser);
