import React from "react";
import { Alert } from "reactstrap";

const ErrorAlert = ({ isOpen, message, toggle }) => (
  <Alert color="danger" isOpen={isOpen} toggle={toggle}>
    {message}
  </Alert>
);

export default ErrorAlert;
