import React, { Fragment, useState } from "react";
import { Button, ListGroupItem, ListGroupItemText } from "reactstrap";
import { FormattedMessage } from "react-intl";
import {
  Detail,
  DetailHeader,
  ExportDataModal,
  navigateToNormalUI,
  ResultTypes,
} from "../../Utilities";
import { FaExternalLinkAlt } from "react-icons/fa";

const ListSiteDetails = ({
  roofId,
  name,
  owner,
  handleResultTypeChange,
  handleErrors,
}) => {
  const [exportModalOpen, setExportModalOpen] = useState(false);
  return (
    <Fragment>
      <ListGroupItem>
        <DetailHeader
          title={<FormattedMessage id="header_siteDetails" />}
          info={<FormattedMessage id="header_siteDetails" />}
        />
        <Detail
          detail={
            <ListGroupItemText>
              <FormattedMessage tagName="strong" id="header_name" />
            </ListGroupItemText>
          }
          middle={
            <div className="d-flex flex-row align-items-center">
              <Button
                className="d-flex flex-row align-items-center devices-owned-btn p-0"
                color="link"
                onClick={(e) => {
                  e.preventDefault();
                  navigateToNormalUI(roofId, "sites");
                }}
              >
                <ListGroupItemText>
                  {!name ? <FormattedMessage id="no_given_identifier" /> : name}
                </ListGroupItemText>
                <FaExternalLinkAlt
                  className="condition-icons margin-left-10px"
                  size={20}
                />
              </Button>
              <div className="margin-left-10px">
                <FormattedMessage tagName={"small"} id="view_site_info" />
              </div>
            </div>
          }
        />
        <Detail
          detail={
            <ListGroupItemText>
              <FormattedMessage tagName="strong" id="header_id" />
            </ListGroupItemText>
          }
          middle={<ListGroupItemText>{roofId}</ListGroupItemText>}
        />

        <Detail
          detail={
            <ListGroupItemText>
              <FormattedMessage tagName="strong" id="header_owner" />
            </ListGroupItemText>
          }
          middle={
            !owner ? (
              ""
            ) : (
              <Button
                className="devices-owned-btn"
                color="link"
                onClick={() =>
                  handleResultTypeChange(owner.id, ResultTypes.TYPE_USERS)
                }
              >
                <p className="btn-p">{owner.email}</p>
              </Button>
            )
          }
        />
        <Detail
          detail={
            <ListGroupItemText>
              <FormattedMessage tagName="strong" id="header_reports" />
            </ListGroupItemText>
          }
          middle={
            <Button
              color="link"
              className="devices-owned-btn"
              onClick={() => setExportModalOpen(true)}
            >
              <FormattedMessage id="site_data_export" />
            </Button>
          }
        />
      </ListGroupItem>
      {exportModalOpen && (
        <ExportDataModal
          siteId={roofId}
          toggle={() => setExportModalOpen(!exportModalOpen)}
          handleErrors={handleErrors}
        />
      )}
    </Fragment>
  );
};

export default ListSiteDetails;
