import React from "react";
import { WiHumidity } from "react-icons/wi";
import { FaTemperatureHigh } from "react-icons/fa";
import { useIntl } from "react-intl";

const LatestMeasurements = (measurements) => {
  const { formatDate, formatMessage } = useIntl();
  const { unit: tempUnit, value: temperature, timestamp } = measurements[0];
  const { unit: humidityUnit, value: humidity } = measurements[1];
  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row">
        <span>
          <FaTemperatureHigh className="condition-icons" size={20} />
        </span>
        <span>{`${
          !temperature === undefined ||
          temperature === null ||
          temperature === false
            ? "--"
            : temperature
        } ${tempUnit}`}</span>

        <span>
          <WiHumidity className="condition-icons" size={30} />
        </span>
        <span>{`${
          humidity === undefined || humidity === null || humidity === false
            ? "--"
            : humidity
        } ${humidityUnit}`}</span>
      </div>
      <div className="d-flex flex-row text-muted">
        <i>
          <small>
            {`${formatMessage({ id: "measured" })} ${
              !timestamp
                ? "--"
                : formatDate(timestamp, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  })
            }`}
          </small>
        </i>
      </div>
    </div>
  );
};

export default LatestMeasurements;
