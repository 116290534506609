import React, { Component } from "react";
import { Button, Modal, ModalHeader } from "reactstrap";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import "./Utilities/Utilities.css";

export const logError = (error, info) => {
  console.log(error, info);
};

class CatchErrors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    //Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    //You can also log the error to an error reporting service
    logError(error, info);
  }

  toggle = () => {
    this.setState({ hasError: !this.state.hasError });
  };

  render() {
    const { formatMessage } = this.props.intl;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Modal
          isOpen={this.state.hasError}
          toggle={this.toggle}
          className="confirmation-dialog text-center"
        >
          <ModalHeader>{formatMessage({ id: "error_main" })}</ModalHeader>
          <Button
            color="primary"
            block
            onClick={() => {
              this.props.history.goBack();
              this.toggle();
            }}
          >
            <h6>{formatMessage({ id: "goBack" })}</h6>
          </Button>
        </Modal>
      );
    }
    return this.props.children;
  }
}

const withIntl = injectIntl(CatchErrors);
export default withRouter(withIntl);
