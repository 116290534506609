import React from "react";
import { ListGroupItemText } from "reactstrap";
import "./Utilities.css";
import DetailMenu from "./DetailMenu";
import { InfoModal } from ".";

export const DetailHeader = ({ title, info }) => (
  <div className="d-flex flex-wrap align-content-center justify-content-between detail-title">
    <div className="p-2 text-left">
      <h6>
        <ListGroupItemText>{title}</ListGroupItemText>
      </h6>
    </div>
    <div className="p-2 ml-auto align-self-right">
      {info && <InfoModal>{info}</InfoModal>}
    </div>
  </div>
);

const Detail = ({
  caret,
  id,
  detail,
  middle,
  muted,
  measurements,
  menuItems,
  isAdmin,
  onClick,
  status,
  style,
  report,
  log,
  pagination,
}) => (
  <div
    id={id && id}
    style={style && style}
    onClick={onClick && onClick}
    className="d-flex flex-wrap align-items-center justify-content-start devive-list-item detail-item"
  >
    {detail && (
      <div className="p-2 text-left">
        <div className="d-flex flex-row align-items-center">
          {status && <div className="transmitter-status-div">{status}</div>}
          <div>{detail}</div>
        </div>

        {muted && (
          <ListGroupItemText className="text-muted">
            <i>{muted}</i>
          </ListGroupItemText>
        )}
      </div>
    )}

    {middle && <div className="p-2 align-self-center">{middle}</div>}
    {menuItems && (
      <div className="p-2 ml-auto align-self-center">
        {<DetailMenu isAdmin={isAdmin}>{menuItems}</DetailMenu>}
      </div>
    )}
    {measurements && (
      <div className="p-2 ml-auto align-self-center">{measurements}</div>
    )}
    {caret && (
      <div
        className={`p-2 align-self-center justify-content-center ${
          !measurements ? "ml-auto" : ""
        }`}
      >
        {caret}
      </div>
    )}
    {report && <div className="p-2 ml-auto">{report}</div>}
    {log && <div className="p-2 flex-grow-1 text-left">{log}</div>}
    {pagination && <div className="p-2 ml-auto text-right">{pagination}</div>}
  </div>
);

export default Detail;
