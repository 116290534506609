import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";

const useForm = (callback) => {
  const [inputs, setInputs] = useState({});

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    callback();
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  return {
    handleSubmit,
    handleInputChange,
    inputs,
  };
};

export default useForm;

export function useErrorHandling() {
  const [visible, setVisibility] = useState(false);
  const [errorText, setErrorText] = useState("");
  const { formatMessage } = useIntl();

  const toggleError = () => {
    setVisibility((isVisible) => {
      if (isVisible) {
        setErrorText("");
        return !isVisible;
      }
    });
  };
  const handleErrors = async (e) => {
    let errorText = "";
    if (e.response) {
      const { statusCode, body } = e.response;

      if (body) {
        const { message } = JSON.parse(body);

        if (message || statusCode) {
          message?.includes("User not found")
            ? (errorText = formatMessage({ id: "user_not_found" }))
            : message?.includes(
                "Invalid request: User with specified email already exists."
              )
            ? (errorText = formatMessage({ id: "error_user_exists" }))
            : message?.includes("Server error: User does not exist.")
            ? (errorText = formatMessage({ id: "server_error_no_user" }))
            : message?.includes("Email address is not verified.")
            ? (errorText = formatMessage({
                id: "error_user_email_not_verified",
              }))
            : message?.includes(
                "Invalid request: Invalid email address format."
              )
            ? (errorText = formatMessage({ id: "error_invalid_email_format" }))
            : message?.includes(
                "Invalid request: API is already enabled for the specified user."
              )
            ? (errorText = formatMessage({ id: "error_api_already_enabled" }))
            : message?.includes(
                "Invalid request: API is already disabled for the specified user."
              )
            ? (errorText = formatMessage({ id: "error_api_already_disabled" }))
            : message?.includes(
                "Invalid request: administrator user cannot be modified."
              )
            ? (errorText = formatMessage({
                id: "error_admin_cannot_be_modified",
              }))
            : (errorText = formatMessage({ id: `error_${statusCode}` }));
        }
      }
    } else if (e.name) {
      errorText = formatMessage({ id: e.name });
    } else {
      errorText = formatMessage({ id: "error_main" });
    }
    setErrorText(errorText);
    setVisibility(true);
  };

  return {
    visible,
    errorText,
    handleErrors,
    toggleError,
  };
}

export const withErrors = (Component) => {
  return (props) => {
    const { handleErrors, visible, errorText, toggleError } =
      useErrorHandling(props);

    return (
      <Component
        handleErrors={handleErrors}
        visible={visible}
        errorText={errorText}
        toggleError={toggleError}
        {...props}
      />
    );
  };
};

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
