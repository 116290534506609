import React, { useState } from "react";
import { Card, Form, FormGroup, Input, InputGroup } from "reactstrap";
import { useIntl } from "react-intl";
import "./forms.css";
import { LoaderButton, ShowHideButton } from "../Buttons";
import { ErrorAlert } from "../Utilities";
import useForm, { useErrorHandling } from "../CustomHooks";
import { signIn, signOut } from "aws-amplify/auth";
import { isAdmin } from "../Frontend-api";

const Login = (props) => {
  const { formatMessage } = useIntl();
  const [type, setType] = useState("password");
  const [isLoading, setLoading] = useState(false);
  const { errorText, visible, toggleError, handleErrors } = useErrorHandling();

  const login = async () => {
    setLoading(true);
    try {
      const user = await signIn({
        username: inputs.email,
        password: inputs.password,
      });
      if (user.nextStep?.signInStep === "DONE") {
        const isUserAdmin = await isAdmin(inputs.email);
        if (!isUserAdmin.result) {
          setLoading(false);
          let e = { name: "user_not_admin" };
          handleErrors(e);
          await signOut();
        } else {
          props.handleLogin(inputs.email);
        }
      }
    } catch (e) {
      if (e.message && e.message === "Unauthorized") {
        handleErrors(e.message);
      } else {
        handleErrors(e);
      }
      await signOut();
      setLoading(false);
    }
  };

  const validateInputs = () => {
    return inputs.email && inputs.password ? true : false;
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(login);

  return (
    <Card className="form-card">
      <ErrorAlert
        isOpen={visible}
        toggle={() => toggleError()}
        message={errorText}
      />
      <div className="img-logo" />
      <h3>{formatMessage({ id: "login_title" })}</h3>
      <Form className="login-form" onSubmit={(e) => handleSubmit(e)}>
        <FormGroup>
          <Input
            name="email"
            type="text"
            maxLength={50}
            placeholder={formatMessage({ id: "email_placeholder" })}
            onChange={handleInputChange}
            disabled={isLoading}
          />
        </FormGroup>
        <FormGroup className="text-left">
          <InputGroup>
            <ShowHideButton
              type={type}
              showHide={() =>
                setType((prev) => (prev === "text" ? "password" : "text"))
              }
            />
          </InputGroup>
          <Input
            name="password"
            type={type}
            maxLength={50}
            placeholder={formatMessage({ id: "password_placeholder" })}
            onChange={handleInputChange}
            disabled={isLoading}
          />
        </FormGroup>
        <LoaderButton
          text="login"
          loadingText="login_loading"
          isLoading={isLoading}
          disabled={!validateInputs()}
        />
      </Form>
    </Card>
  );
};

export default Login;
