import React, { memo, useState } from "react";
import MainMenu from "../MainMenu";
import { FormattedMessage } from "react-intl";
import "./Home.css";
import { Switch } from "react-router-dom";
import AuthenticatedRoute from "../Routes/AuthenticatedRoute";
import SearchUsers from "../MainMenuContent/SearchUsers";
import SearchDevices from "../MainMenuContent/SearchDevices";
import SearchSites from "../MainMenuContent/SearchSites";

const HomeContent = () => (
  <div className="home-content">
    <div className="home-text">
      <h3>
        <FormattedMessage id="home_title" />
      </h3>
      <p>
        <FormattedMessage id="home_search_guide" />
      </p>
    </div>
    <div className="home-content-filler"></div>
  </div>
);

const Home = (props) => {
  const [deviceResults, setDeviceResults] = useState([]);
  const [deviceSearchTerm, setDeviceSearchTerm] = useState("");
  const [userResults, setUserResults] = useState([]);
  const [userSearchTerm, setUserSearchTerm] = useState([]);
  const [siteResults, setSiteResults] = useState([]);
  const [siteSearchTerm, setSiteSearchTerm] = useState("");
  return (
    <div className="home-container">
      <div className="main-navigation">
        <MainMenu
          currentUser={props.user}
          handleLogout={props.handleLogout}
          location={props.location}
        />
      </div>
      <div className="main-content">
        <Switch>
          <AuthenticatedRoute
            exact
            path="/"
            component={HomeContent}
            props={props}
          />
          <AuthenticatedRoute
            path="/users"
            component={SearchUsers}
            props={{
              setResults: setUserResults,
              results: userResults,
              searchTerm: userSearchTerm,
              setTerm: setUserSearchTerm,
              ...props,
            }}
          />
          <AuthenticatedRoute
            path="/devices"
            component={SearchDevices}
            props={{
              setResults: setDeviceResults,
              results: deviceResults,
              searchTerm: deviceSearchTerm,
              setTerm: setDeviceSearchTerm,
              ...props,
            }}
          />
          <AuthenticatedRoute
            path="/sites"
            component={SearchSites}
            props={{
              setResults: setSiteResults,
              results: siteResults,
              searchTerm: siteSearchTerm,
              setTerm: setSiteSearchTerm,
              ...props,
            }}
          />
        </Switch>
      </div>
    </div>
  );
};

export default memo(Home);
