import React, { Fragment } from "react";
import { Button, ListGroupItem, ListGroupItemText, Table } from "reactstrap";
import { FormattedMessage, FormattedDate } from "react-intl";
import {
  Detail,
  DetailHeader,
  ResultTypes,
  StatusIndicator,
} from "../../Utilities";

const ListConnectedSites = ({ sites, handleResultTypeChange }) => {
  return (
    <ListGroupItem>
      <DetailHeader
        title={<FormattedMessage id="sites_connected" />}
        info={<FormattedMessage id="details_info_connected_sites" />}
      />
      {!sites || !sites.length ? (
        <ListGroupItemText className="text-muted mt-2">
          <FormattedMessage id="no_connected_sites" />
        </ListGroupItemText>
      ) : (
        sites.map((site) => (
          <Detail
            key={site.id}
            detail={
              <Button
                className="devices-owned-btn"
                color="link"
                onClick={() =>
                  handleResultTypeChange(site.id, ResultTypes.TYPE_SITES)
                }
              >
                <p>{site.name}</p>
              </Button>
            }
            middle={
              site.isInstalled && <FormattedMessage id="installed_to_site" />
            }
          />
        ))
      )}
    </ListGroupItem>
  );
};

const ListConnectedDevices = ({ devices, handleResultTypeChange }) => {
  return (
    <ListGroupItem>
      <DetailHeader
        title={<FormattedMessage id="devices_connected" />}
        info={<FormattedMessage id="details_info_connected_devices" />}
      />
      {!devices || !devices.length ? (
        <ListGroupItemText className="text-muted mt-2">
          <FormattedMessage id="no_connected_devices" />
        </ListGroupItemText>
      ) : (
        <Table responsive className="connected-devices-table m-0">
          <tbody>
            {devices.map(({ id, serialNumber, name, type }) => (
              <tr className="connected-devices-row" key={id}>
                <td>
                  {
                    <Button
                      className="devices-owned-btn"
                      color="link"
                      onClick={() =>
                        handleResultTypeChange(id, ResultTypes.TYPE_DEVICES)
                      }
                    >
                      {!name ? serialNumber : name}
                    </Button>
                  }
                </td>
                <td>{!name ? "" : serialNumber}</td>
                <td>{type}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </ListGroupItem>
  );
};

const ListCcuDetails = ({
  id,
  type,
  serialNumber,
  isOnline,
  name,
  owner,
  devices,
  sites,
  configurationUpdateTime,
  latestCommunicationTime,
  handleResultTypeChange,
}) => {
  return (
    <Fragment>
      <ListGroupItem>
        <DetailHeader
          title={<FormattedMessage id="header_deviceDetails" />}
          info={<FormattedMessage id="header_deviceDetails" />}
        />
        <Detail
          detail={
            <ListGroupItemText>
              <FormattedMessage tagName="strong" id="header_name" />
            </ListGroupItemText>
          }
          middle={
            <div className="d-flex flex-row align-items-center">
              <StatusIndicator className="mr-2" isOnline={isOnline} />
              <ListGroupItemText>
                {!name ? (
                  <FormattedMessage
                    tagName={"i"}
                    className="text-muted"
                    id="no_given_identifier"
                  />
                ) : (
                  name
                )}
              </ListGroupItemText>
            </div>
          }
        />
        <Detail
          detail={
            <ListGroupItemText>
              <FormattedMessage tagName="strong" id="header_id" />
            </ListGroupItemText>
          }
          middle={<ListGroupItemText>{id}</ListGroupItemText>}
        />
        <Detail
          detail={
            <ListGroupItemText>
              <FormattedMessage tagName="strong" id="header_serial" />
            </ListGroupItemText>
          }
          middle={<ListGroupItemText>{serialNumber}</ListGroupItemText>}
        />
        <Detail
          detail={
            <ListGroupItemText>
              <FormattedMessage tagName="strong" id="header_type" />
            </ListGroupItemText>
          }
          middle={<ListGroupItemText>{type}</ListGroupItemText>}
        />

        <Detail
          detail={
            <ListGroupItemText>
              <FormattedMessage tagName="strong" id="header_owner" />
            </ListGroupItemText>
          }
          middle={
            !owner ? (
              ""
            ) : (
              <Button
                className="devices-owned-btn"
                color="link"
                onClick={() =>
                  handleResultTypeChange(owner.id, ResultTypes.TYPE_USERS)
                }
              >
                <p className="btn-p">{owner.email}</p>
              </Button>
            )
          }
        />
        <Detail
          detail={
            <ListGroupItemText>
              <FormattedMessage
                tagName="strong"
                id="header_gateway_latestCommunicationTime"
              />
            </ListGroupItemText>
          }
          middle={
            <ListGroupItemText>
              {latestCommunicationTime ? (
                <FormattedDate
                  value={latestCommunicationTime}
                  year="numeric"
                  month="2-digit"
                  day="2-digit"
                  hour="2-digit"
                  minute="2-digit"
                  second="2-digit"
                />
              ) : (
                "-"
              )}
            </ListGroupItemText>
          }
        />
        <Detail
          detail={
            <ListGroupItemText>
              <FormattedMessage
                tagName="strong"
                id="header_gateway_configurationUpdateTime"
              />
            </ListGroupItemText>
          }
          middle={
            <ListGroupItemText>
              {configurationUpdateTime ? (
                <FormattedDate
                  value={configurationUpdateTime}
                  year="numeric"
                  month="2-digit"
                  day="2-digit"
                  hour="2-digit"
                  minute="2-digit"
                  second="2-digit"
                />
              ) : (
                "-"
              )}
            </ListGroupItemText>
          }
        />
      </ListGroupItem>
      <ListConnectedDevices
        devices={devices}
        handleResultTypeChange={handleResultTypeChange}
      />
      <ListConnectedSites
        sites={sites}
        handleResultTypeChange={handleResultTypeChange}
      />
    </Fragment>
  );
};

export default ListCcuDetails;
