import React from "react";
import { FaRss, FaExclamationTriangle } from "react-icons/fa";

const StatusIndicator = ({ isOnline, isAlert, className }) => (
  <div
    className={className ? `status-indicator ${className}` : "status-indicator"}
  >
    {!isOnline ? (
      <FaRss id="status-indicator-offline" />
    ) : !isAlert ? (
      <FaRss id="status-indicator-online" />
    ) : (
      <FaExclamationTriangle id="status-indicator-danger" />
    )}
  </div>
);

export default StatusIndicator;
