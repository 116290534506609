import React from "react";
import { NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";

const MainMenuButton = (props) => (
  <NavItem active={props.active}>
    <NavLink
      to={props.id}
      className="nav-link"
      activeClassName="nav-link active"
    >
      {props.children}
    </NavLink>
  </NavItem>
);

export default MainMenuButton;
