import React from "react";
import { Form, FormGroup, Modal } from "reactstrap";
import { LoaderButton, CancelButton } from "../Buttons";
import "./Utilities.css";

const ConfirmationModal = ({
  children,
  handleSubmit,
  isOpen,
  toggle,
  isLoading,
  loadingText,
  text
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="confirmation-dialog text-center"
      backdrop={false}
    >
      <Form onSubmit={handleSubmit}>
        <FormGroup>{children}</FormGroup>
        <LoaderButton
          isLoading={isLoading}
          loadingText={loadingText}
          text={text}
        />
        <CancelButton onClick={toggle} />
      </Form>
    </Modal>
  );
};

export default ConfirmationModal;
